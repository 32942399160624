import { ref, computed } from 'vue';
import { defineStore } from 'pinia';
import axios from 'axios'
import { Local } from '/@/utils/storage';

/** 区域树节点*/
export interface RegionTree {
    label: string,
    value: string,
    leaf: boolean,
    children: RegionTree[]
}
/** 区域节点*/
export interface RegionNode {
    label: string,
    value: string,
    parentValue: string,
    level: number,
    leaf: boolean
}

// /** 区域数据*/
// declare interface RegionData {
//     regionData: Region[]
// }

/**
 * 行政区域
 */
export const useRegionData = defineStore('regionData', () => {
    const regionData = ref<RegionTree[]>([{ label: '未加载行政区域', value: '0', leaf: true, children: [] }]);

    /**
      * 行政区域树
      */
    const regionTree = computed((): RegionTree[] => {
        return regionData.value;
    });

    /**
     * 行政区域字典
     */
    const regionDic = computed((): Record<string, string> => {
        const dic: Record<string, string> = {};
        const traverse = (node: RegionTree, name: string = "") => {
            const fullName = name + node.label;
            dic[node.value] = fullName;
            node.children?.forEach(c => traverse(c, fullName));
        };
        regionData.value.forEach(node => traverse(node));
        return dic;
    });

    /**
     * 行政区域节点
     */
    const regionNodes = computed((): RegionNode[] => {
        const nodes: RegionNode[] = [];
        const traverse = (node: RegionTree, parentValue: string = "", level: number = 0) => {
            nodes.push({ label: node.label, value: node.value, parentValue, level, leaf: !node.children || node.children.length === 0 });
            node.children?.forEach(c => traverse(c, node.value, level + 1));
        };
        regionData.value.forEach(node => traverse(node));
        return nodes;
    });

    /**
     * 重写行政区域数据
     * https://registry.npmmirror.com/china-division/2.7.0/files/dist/pcas-code.json
     */
    async function setRegionData(forceRefresh: boolean = false): Promise<RegionTree[]> {
            try {
                const data = Local.get('regionData');
                if (!forceRefresh && data) {
                    regionData.value = data;
                    return regionData.value as RegionTree[];
                }

                const res = await axios.get('https://registry.npmmirror.com/china-division/2.7.0/files/dist/pcas-code.json')

                const transformItem = (item: any): RegionTree => ({
                    value: item.code,
                    label: item.name,
                    leaf: !item.children || item.children.length === 0,
                    children: item.children ? item.children.map(transformItem) : []
                });
                regionData.value = res.data.map(transformItem);
                Local.set('regionData', regionData.value)
            } catch (error) {
                regionData.value = [{ label: '行政区域加载失败', value: '0', leaf: true, children: [] }];
                console.log(error);
            }

            return regionData.value as RegionTree[];
        }

    return { regionTree, regionDic, regionNodes, setRegionData };
});
