import { createApp, Component } from 'vue';
import pinia from '/@/stores/index';
import App from '/@/App.vue';
import router from '/@/router';
import { directive } from '/@/directive/index';
import { i18n } from '/@/i18n/index';
import other from '/@/utils/other';
import errorHandler from '/@/utils/errorHandler';

import ElementPlus from 'element-plus';
import '/@/theme/index.scss';

import { Splitpanes, Pane } from 'splitpanes';
import 'animate.css'; // 动画库
import 'splitpanes/dist/splitpanes.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import * as ProIcons from '@/assets/icons/index.ts'

const app = createApp(App);

directive(app);
other.elSvg(app);

app.component('Splitpanes', Splitpanes);
app.component('Pane', Pane);


//统一注册el-icon图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
//统一注册pro-icon图标
// 使用
//<el-icon>
// <component :is="Lock" />
// </el-icon>
for (const [key, component] of Object.entries(ProIcons) as [string, Component][]) {
    app.component(`ProIcon${key}`, component)
}

//全局代码错误捕捉
app.config.errorHandler = errorHandler;
app.use(pinia)
app.use(router)
app.use(ElementPlus)
app.use(i18n)
app.mount('#app');
