import { computed } from 'vue';
import { defineStore } from 'pinia';

import useUniformRequest from '/@/hooks/useUniformRequest';
import { DicCategoryApiFactory, DicDetailData } from '/@/api/api';

/**
 * 字典列表
 * @methods setDictList 设置字典列表
 */
export const useDictList = defineStore('dictList', () => {
	const { data: dictList, refreshAsync } = useUniformRequest(DicCategoryApiFactory().getDicCategoryList);

	/**
    * 全部字典明细对象
    */
	const dictItemObject = computed((): Record<string, Record<string, DicDetailData>> => {
		const dic: Record<string, Record<string, DicDetailData>> = {};
		dictList.value?.forEach((item) => {
			dic[item.code] = {};
			item.dicDataList?.forEach((detail) => {
				dic[item.code][detail.value] = detail;
			});
		});
		return dic;
	});

	/**
	 * 全部字典明细列表
	 */
	const dictItemArray = computed((): Record<string, DicDetailData[]> => {
		const dic: Record<string, DicDetailData[]> = {};
		dictList.value?.forEach((item) => {
			dic[item.code] = item.dicDataList ?? [];
		});
		return dic;
	});

	return { dictList, refreshAsync, dictItemObject, dictItemArray };
});
